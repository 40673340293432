export const useCountdown = () => {
  const tournamentDateEnds = ref<{
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const intervalId = ref<NodeJS.Timeout | undefined>(undefined);
  const currentDate = ref<number>(new Date().getTime());

  /**
   * Starts the countdown timer with the given finish date.
   * The timer updates every second.
   * @param finishDate The finish date in milliseconds since the Unix epoch.
   */
  const startCountdown = (finishDate: number | undefined) => {
    if (intervalId.value === undefined && finishDate !== undefined) {
      intervalId.value = setInterval(() => {
        updateCountdown(finishDate);
      }, 1000);
      updateCountdown(finishDate);
    }
  };

  /**
   * Stops the countdown timer.
   */
  const stopCountdown = () => {
    if (intervalId.value !== undefined) {
      clearInterval(intervalId.value);
      intervalId.value = undefined;
    }
  };

  /**
   * Updates the countdown timer with the given finish date.
   * Calculates the remaining days, hours, minutes, and seconds.
   * @param finishDate The finish date in milliseconds since the Unix epoch.
   */
  const updateCountdown = (finishDate: number) => {
    currentDate.value = new Date().getTime();
    const timeDifference = finishDate! - currentDate.value;
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60),
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    tournamentDateEnds.value = {
      days,
      hours,
      minutes,
      seconds,
    };

    if (timeDifference <= 0) {
      stopCountdown();
    }
  };

  return {
    tournamentDateEnds,
    startCountdown,
    stopCountdown,
    currentDate,
  };
};
